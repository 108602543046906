import React, { FC } from 'react'
import { Col, Row, Tooltip } from 'antd'

import DiscountBar from '../../../../components/DiscountBar'

import { PropTypes } from './types'

import styles from './styles.module.css'

const SCCHeader: FC<PropTypes> = ({
  userName,
  executiveName,
  executiveEmail,
  executivePhone,
}) => {

  const renderLeftPanel = () => (
    <div className={styles.leftPanel}>
      <div className={styles.welcomeMessageFont}>
        ¡Bienvenido de nuevo, {userName}!
      </div>
      <div className={styles.welcomeMessageDescriptionFont}>
        Con Simplus, puedes comprar y administrar todos los viajes de trabajo de
        manera rápida y sencilla.
      </div>
    </div>
  )
  
  const renderExecutive = () => (
      <Row className={styles.executive} justify="start" align="middle">
        <Col span={24}>
          <div className={styles.label}>Ejecutivo asignado</div>
        </Col>
        <Col span={24}>
          <div className={styles.executiveName}>
            {(executiveName == null || executiveName === '' || executiveName === 'undefined undefined' || executiveName === 'undefined') ? 'Aún no tienes un ejecutivo asignado...' : `${executiveName}`}
          </div>
        </Col>
        <Col span={24}>
          {executiveEmail != null && executiveEmail !== undefined && executivePhone != null && executivePhone !== undefined ? (
            <Tooltip
              placement="rightTop"
              title={`Correo: ${executiveEmail} Teléfono: ${executivePhone}`}>
              <div className={styles.contactFont}>Contacto</div>
            </Tooltip>
          ) : (
            null
          )}
        </Col>

    </Row>
  )

  const renderRightPanel = () => {
    return (
      <div className={styles.rightPanel}>
        {renderExecutive()}
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {renderLeftPanel()}
      {renderRightPanel()}
    </div>
  )
}

export default SCCHeader
